<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8 mx-auto mt-4">
        <h1 v-if="patient != null">
          Add a Service to client {{ patient.last + ", " + patient.first }}
        </h1>
      </div>
    </div>
    <div class="card theme-card mx-auto col-12 col-md-8 shadow-card">
      <div class="card-body">
        <alert v-if="isLoading" class="mb-0" />

        <form @submit.prevent="addService" validate>
          <div class="form-group">
            <div
              class="mb-2 d-flex align-items-center"
              :class="
                displayedServices.length && !isLoading
                  ? 'justify-content-between'
                  : 'justify-content-end'
              "
            >
              <span v-if="displayedServices.length && !isLoading">Service</span>
              <select-control
                v-if="!isLoading"
                v-model="selectedStatus"
                :options="serviceStatuses"
                type="horizontal"
                labelClass="d-none"
                wrapClass="mb-0"
                class="service-types"
              ></select-control>
            </div>
            <select
              class="form-control"
              :required="true"
              v-model="service.id"
              v-if="displayedServices.length && !isLoading"
            >
              <option
                v-for="item in displayedServices"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <save :saving="isSaving" v-if="displayedServices.length && !isLoading"
            >Attach Service</save
          >
          <button
            v-if="src && displayedServices.length && !isLoading"
            class="btn btn-link ml-2"
            @click.prevent="navigateBack"
          >
            Cancel
          </button>
        </form>

        <div v-if="!displayedServices.length && !isLoading" class="mb-3 pt-3">
          No Available Services or All Services have been assigned to the
          selected client
        </div>
        <div
          v-if="src && !displayedServices.length && !isLoading"
          class="row justify-content-end mx-0"
        >
          <button class="btn btn-link ml-2" @click.prevent="navigateBack">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
      isLoading: (state) => state.services.isLoading,
      services: (state) => state.services.parentServicesBasic,
      clientServices: (state) => state.services.userServices,
    }),
    src: function () {
      return this.$route.query.src;
    },
    displayedServices: function () {
      let servicesTodisplay = [];
      if (this.services && this.services.length) {
        servicesTodisplay = this.services.filter(
          (serv) => !this.clientServices.includes(serv.id)
        );
        if (this.selectedStatus) {
          servicesTodisplay = servicesTodisplay.filter(
            (serv) => serv.status == this.selectedStatus
          );
        }
      }
      return servicesTodisplay;
    },
  },
  data() {
    return {
      isSaving: false,
      service: {
        id: null,
        user_id: null,
      },
      selectedStatus: "ACTIVE",
      serviceStatuses: [
        { value: "", label: "All" },
        { value: "ACTIVE", label: "Active" },
        { value: "INACTIVE", label: "Inactive" },
      ],
    };
  },
  mounted() {
    if (this.patient == null) {
      return this.$router.back();
    }
    this.getServices();
  },
  methods: {
    ...mapActions({
      attachService: "services/attachServiceToClient",
      getServices: "services/getParentServicesNames",
    }),
    addService: function () {
      let vm = this;
      this.isSaving = true;
      let data = {
        service_id: this.service.id,
        user_id: this.patient.user_id,
      };
      this.attachService(data).then(function (created) {
        vm.isSaving = false;
        if (created) {
          vm.$router.back();
        }
      });
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
.service-types {
  .form-control {
    height: 32px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
